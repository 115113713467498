<template>
  <div id="app">
    <router-view />
    <BoradSide></BoradSide>
    <LinkDialog></LinkDialog>

  </div>
</template>
<script>
import BoradSide from "@/components/broadSide/index.vue";
import LinkDialog from "@/components/linkDialog/index.vue";

export default {
  components: {
    BoradSide,
    LinkDialog
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
.reverse {
  rotate: 180deg;
}

#app {
  font-family: "微软雅黑", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 1200px;
  //background-color: #eeeeee;
  overflow-x: hidden;
}
</style>
