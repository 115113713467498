/*
 * @Author: 关
 * @Date: 2022-08-29 15:03:00
 * @LastEditTime: 2022-08-29 15:03:00
 * @LastEditors: 关
 * @Description: axios请求配置封装
 * @FilePath: \tp-vue2\src\http\axios.js
 */

import { serialize } from '@/utils/util'
import qs from 'qs'
import store from '@/store'
import axios from 'axios';
import axiosConfig from './axios.config';
const { tokenType }=axiosConfig;
// 请求超时的毫秒数(0 表示无超时时间)
axios.defaults.timeout = 30000;

// 定义一个自定义HTTP状态码的错误范围，返回 `true`，promise 将被 resolve; 否则，promise 将被 rejecte
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
}
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true;

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 凭证相关
    const isNotToken = (config.headers || {}).isToken === false; // 当前请求是否不需要验证凭证
    const isConfigAuth = (config.headers || {}).Authorization; // 当前请求是否已配置了请求凭证
    if (!isNotToken&&!isConfigAuth) {
        const token = store.getters.access_token; // token从store中获取
        config.headers['Authorization'] = tokenType+' '+token;
    }
    // get请求时params参数开启序列化
    if (config.method === 'get') {
        config.paramsSerializer = function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' })
        }
    }
    // 检查请求主体是否需要序列化，只有post请求和headers中配置serialize为true时开启
    if (config.method === 'post' && config.headers.serialize) {
        config.data = serialize(config.data);
        delete config.headers.serialize;
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default axios;
