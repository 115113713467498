/*
 * @Author: 关
 * @Date: 2022-08-29 15:03:00
 * @LastEditTime: 2022-08-29 15:03:00
 * @LastEditors: 关
 * @Description: 登陆相关接口
 * @FilePath: \tp-vue2\src\api\login.js
 */

import request from '@/http/axios';
import qs from "qs";
export function login(params){
    let grant_type='password';
    let dataObj = qs.stringify(params)
    return request({
        url: '/auth/oauth/token',
        method: 'post',
        headers: {
            isToken:false,
            "TENANT-ID": 1,
            'Authorization': 'Basic cGlnOnBpZw=='
        },
        params:{grant_type},
        data:dataObj
    })
}
export const logout = () => {
    return request({
        url: '/auth/token/logout',
        method: 'delete'
    })
}
