/*
 * @Author: 关
 * @Date: 2022-08-29 15:03:00
 * @LastEditTime: 2022-08-29 15:03:00
 * @LastEditors: 关
 * @Description: axios可动态调整的配置
 * @FilePath: \tp-vue2\src\http\axios.config.js
 */

export default{
    tokenType:'Bearer', // 凭证的类型
}
