<template>
  <div>
    <div class="fixed_box">
      <el-popover
        placement="left"
        trigger="hover"
        v-for="(item, index) in fixedList"
        :key="item.value"
        :disabled="item.value == 'tra'"
      >
        <div class="tel" v-show="item.value == 'tel'">
          <div class="telNum">400-800-3550（服务热线）</div>
          <div class="telNum">0311-85291868（石家庄）</div>
        </div>
        <div v-show="item.value == 'num'" class="num">
          <img :src="numUrl" alt="" />
        </div>
        <div v-show="item.value == 'mini'" class="mini">
          <img :src="miniUrl" alt="" />
        </div>
        <div v-show="item.value == 'app'" class="app">
          <img :src="appUrl" alt="" />
        </div>
        <div slot="reference" class="fixed_row" @click="toWeb(item.value)">
          <img :src="item.imgUrl" alt="" />
          <div v-html="item.name"></div>
        </div>
      </el-popover>
    </div>
    <div class="fixed_top" @click="scrollToTop" v-show="bool">
      <img :src="topUrl" alt="" />
    </div>
  </div>
</template>

<script>
const cubic = (value) => Math.pow(value, 3);
const easeInOutCubic = (value) =>
  value < 0.5 ? cubic(value * 2) / 2 : 1 - cubic((1 - value) * 2) / 2;
export default {
  name: "BoradSide",

  data() {
    return {
      bool: false,
      numUrl: require("@/assets/homepage/num.png"),
      miniUrl: require("@/assets/homepage/mini.png"),
      appUrl: require("@/assets/homepage/app.png"),
      topUrl: require("@/assets/homepage/top.png"),
      fixedList: [
        {
          name: "电话",
          value: "tel",
          imgUrl: require("@/assets/homepage/fixed1.png"),
        },
        {
          name: "公众号",
          value: "num",
          imgUrl: require("@/assets/homepage/fixed2.png"),
        },
        {
          name: "小程序",
          value: "mini",
          imgUrl: require("@/assets/homepage/fixed3.png"),
        },
        {
          name: "手机APP",
          value: "app",
          imgUrl: require("@/assets/homepage/fixed4.png"),
        },
        {
          name: "哈尔滨<br>运输服务",
          value: "tra",
          imgUrl: require("@/assets/homepage/fixed5.png"),
        },
      ],
    };
  },

  methods: {
    toWeb(value) {
      if (value == "tra") {
        window.open("http://111.42.74.45:701/");
      }
    },
    returnTop() {
      var ksHeight = document.documentElement.offsetHeight;
      var scrollT =
        document.documentElement.scrollTop || document.body.scrollTop;
      var scrollH = document.body.scrollHeight;
      if (ksHeight + scrollT >= scrollH + 100) {
        window.scrollBy(0, -ksHeight);
      }
    },
    handleScroll() {
      var ksHeight = document.documentElement.offsetHeight;
      var scrollT =
        document.documentElement.scrollTop || document.body.scrollTop;
      var scrollH = document.body.scrollHeight;
      if (ksHeight + scrollT > scrollH) {
        this.bool = true;
      } else {
        this.bool = false;
      }
      return this.bool;
    },
    scrollToTop() {
      const el = document.documentElement;
      const beginTime = Date.now();
      const beginValue = el.scrollTop;

      const rAF =
        window.requestAnimationFrame || ((func) => setTimeout(func, 16));
      const frameFunc = () => {
        const progress = (Date.now() - beginTime) / 500;
        if (progress < 1) {
          el.scrollTop = beginValue * (1 - easeInOutCubic(progress));
          rAF(frameFunc);
        } else {
          el.scrollTop = 0;
        }
      };
      rAF(frameFunc);
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>
<style scoped lang="scss">
.fixed_box {
  position: fixed;
  top: 100px;
  right: 0px;
  width: 100px;
  height: 697px;
  background: #ffffff;
  box-shadow: 0px 0px 15px 0px rgba(1, 77, 214, 0.13);
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  box-sizing: border-box;
  padding: 20px 0 20px 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  font-size: 18px;
  font-family: Source Han Sans CN-Normal, Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  .fixed_row {
    cursor: pointer;
  }
  img {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
  }
}
.fixed_top {
  position: fixed;
  top: 830px;
  right: 0px;
  z-index: 1000;
  width: 100px;
  height: 100px;
  background: #ffffff;
  box-shadow: 0px 0px 15px 0px rgba(1, 77, 214, 0.13);
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.tel {
  height: 100px;
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  .telNum {
    font-size: 20px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #295bdd;
    line-height: 23px;
  }
}
</style>
